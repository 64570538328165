.transaction-resum-modal{
    .title{
        display: flex;
        align-items: flex-end;
        font-size: 1.5rem;
    }
    .icon{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 0.5rem;
        font-size: 1.3rem;
        margin-right: 10px;
    }
    p{
        font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.1px;
          color: #878AA0;
          text-transform: capitalize;
    }
    ::-webkit-scrollbar {
        width: 5px;
      }
    
      /* Track */
      ::-webkit-scrollbar-track {
        background: white;
      }
    
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: rgb(241, 241, 241);
      }
    
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: rgb(228, 228, 228);
      }
}
.bankingSystemFeature {
    background: #ffffff;
    border-radius: 5px;
    display: flex;
  
    @media (max-width: 767px) {
      display: block;
    }
  
    .bsFeatureItem {
      flex: 1;
      border-right: 1px solid #F1F1F5;
      padding: 30px;
      display: flex;
      flex-wrap: wrap;
  
      @media (max-width: 767px) {
        border-right: none;
        border-bottom: 1px solid #F1F1F5;
      }
  
      &:last-child {
        border: none;
      }
  
      .icon {
        margin-right: 30px;
  
        @media (min-width: 768px) and (max-width: 1200px) {
          margin-bottom: 10px;
        }
      }
  
      .content {
        h2 {
          font-style: normal;
          font-weight: bold;
          font-size: 28px;
          line-height: 34px;
          letter-spacing: 0.116667px;
          color: #111026;
          margin-bottom: 10px;
          @media (min-width: 768px) and (max-width: 1200px) {
            font-size: 25px;
          }
        }
  
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.1px;
          color: #878AA0;
        }
      }
    }
  }
  