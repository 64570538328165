.editProfile {
  label {
    display: block;
    margin-bottom: 5px;
    text-transform: unset;
    font-size: 12px;
  }
  button {
    text-transform: unset;
  }
  .textField {
    background: #fafafa;
    fieldset {
      border: 1px solid #f1f1f1;
    }
    &:hover {
      fieldset {
        border-color: #e5e5e5;
      }
    }
  }

  .remove-Icon {
    cursor: pointer;
    font-size: 1rem;
    transition: 0.15s;
    svg{
      color: red;
      margin-left: 0.1rem;
    }

    &:hover{
      font-size: 1.001rem;
      svg{
        filter: brightness(0.9);
      }
    }
  }

  .transactions-scroll {
    // margin-right: 1rem ;
  }
}

.modalWrapper {
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(241, 241, 241);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(228, 228, 228);
  }
}

.fileUpload {
  .dzu-dropzone {
    overflow: hidden;
    background: #fafafa;
    border: 1px solid #f1f1f1;

    .dzu-inputLabel {
      font-size: 14px;
      color: #8f8f8f;
      text-transform: unset;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
    }

    .dzu-previewContainer {
      padding: 30px 25px;

      img {
        max-height: unset;
      }

      .dzu-previewStatusContainer {
      }
    }

    .dzu-inputLabelWithFiles {
      border: none;
      font-size: 14px;
      color: #fff;
      font-weight: 400;
      background: linear-gradient(to right, #ff5e62, #ff9966) !important;
    }

    .dzu-submitButtonContainer {
      button {
        background: #28a745 !important;
        border: none;
        font-size: 14px;
        font-weight: 400;
        color: #fff;

        &:focus {
          outline: none;
        }
      }
    }
  }
}
