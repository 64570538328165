.responsiveRechart {
  width: 100%;
  height: 300px;

  @media (max-width: 767px) {
    height: 200px;
  }
  margin-top: 42px;

  .recharts-default-legend {
    margin-top: -70px !important;
    @media (max-width: 767px) {
      margin-top: -40px !important;
    }
  }
}

h1,h2,h3,h4,h5,h6{
  text-transform: unset !important;
}

.lineRechart {
  width: 100%;
  height: 320px;
  @media (max-width: 767px) {
    margin-top: 50px;
  }
}

.customTooltip {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #f1f1f1;
  padding: 15px;

  li {
    &.label {
      span {
        font-weight: 600;
        color: #343a40 !important;
      }
    }
  }
}

.custom-tooltip {
  background: #fff;
  padding: 10px 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  text-align: center;

  h4 {
    margin-bottom: 5px;
    font-size: 20px;
  }

  p {
    font-size: 13px;
    color: #878aa0;
  }
}


body {
  .cBtn {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    height: 50px;
    min-width: 150px;
    text-align: center;
    letter-spacing: 0.1px;
    text-transform: capitalize;

    &.cBtnSmall {
      font-size: 12px;
      height: auto;
      min-width: auto;
      padding-right: 10px;
      padding-left: 10px;
    }

    &.cBtnPrimary {
      background: rgba(14, 183, 254, 0.1);
      color: #0EB7FE;
    }

    &.cBtnTheme {
      background: rgba(59, 83, 219, 0.1);
      color: #3B53DB;
    }

    &.cBtnDanger {
      background: rgba(241, 104, 44, 0.1);
      color: #F1682C;
    }
  }
}
