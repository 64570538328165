@import "../docs/css/mixin.scss";

.mainContainer {
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-wrap: nowrap;

    @media (max-width: 991px) {
        display: block;
        overflow: hidden;
    }

    .mainContentRouter {
        width: calc(100% - 320px);

        @media (max-width: 1440px) {
            width: calc(100% - 230px);
        }

        @media (max-width: 991px) {
            width: 100%;
        }

        .headerArea {
            padding: 0 60px;

            @media (max-width: 1500px) {
                padding: 0px 30px 0;
            }

            @media (max-width: 1279px) {
                padding: 0 30px 0;
            }

            @media (max-width: 991px) {
                padding: 0 16px;
            }
        }

        .mainCotentInner {
            padding: 0 60px 50px;

            @media (max-width: 1500px) {
                padding: 0px 30px 50px;
            }

            @media (max-width: 1279px) {
                padding: 0 30px 50px;
            }

            @media (max-width: 991px) {
                padding: 0 16px 50px;
            }

            @media (max-width: 991px) {
                padding: 0 16px 50px;
            }
        }
    }

    &.mainContainerColups {
        .mainContentRouter {
            width: calc(100% - 100px);

            @media (max-width: 991px) {
                width: 100%;
            }
        }

        .sidebarMainWrapper {
            width: 100px;
            max-width: 100px;
            min-width: 100px;
            overflow: hidden;

            @media (max-width: 991px) {
                width: 280px;
                min-width: 280px;
                max-width: 280px;
                left: 0;
            }

            .sidebarWrap {
                background: #eae8f7;
                z-index: 11;
                width: 100px;
                max-width: 100px;
                min-width: 100px;

                @media (max-width: 991px) {
                    width: 280px;
                    min-width: 280px;
                    max-width: 280px;
                }

                .logo {
                    a {
                        img {
                            &.colupsLogo {
                                opacity: 1;

                                @media (max-width: 991px) {
                                    opacity: 0;
                                }
                            }

                            &.normal {
                                opacity: 0;
                                visibility: hidden;

                                @media (max-width: 991px) {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }

                .profileWrap {
                    padding: 0 27px 30px;

                    span {
                        &.profileImg {
                            img {
                                width: 100%;

                                @media (max-width: 991px) {
                                    width: 70px;
                                }
                            }
                        }

                        &.name {
                            display: block;
                            text-align: center;

                            @media (max-width: 991px) {
                                text-align: left;
                            }

                            .text {
                                font-size: 0px;

                                @media (max-width: 991px) {
                                    font-size: 18px;
                                }
                            }

                            i {
                                margin-left: 0px;

                                @media (max-width: 991px) {
                                    margin-left: 10px;
                                }
                            }
                        }
                    }
                }

                .sidebarMenu {
                    .menuLabel {
                        display: none;

                        @media (max-width: 991px) {
                            display: block;
                        }
                    }

                    .submenu {
                        display: none;

                        @media (max-width: 991px) {
                            display: block;
                        }
                    }

                    .navItems {
                        .navItemsTextContent {
                            .icon {
                                height: 21px;
                            }

                            .name {
                                display: none;

                                @media (max-width: 991px) {
                                    display: block;
                                }
                            }

                            .value {
                                display: none;

                                @media (max-width: 991px) {
                                    display: flex;
                                }
                            }
                        }
                    }
                }
            }

            &:hover {
                .sidebarWrap {
                    background: #eae8f7;
                    z-index: 11;
                    width: 305px;
                    max-width: 305px;
                    min-width: 305px;
                    z-index: 11;

                    @media (max-width: 991px) {
                        width: 280px;
                        min-width: 280px;
                        max-width: 280px;
                    }

                    .logo {

                        a {
                            img {
                                &.colupsLogo {
                                    opacity: 0;
                                }

                                &.normal {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }

                    .profileWrap {
                        padding: 0 40px 50px;

                        @media (max-width: 991px) {
                            padding: 0px 27px 30px;
                        }

                        span {
                            &.profileImg {
                                img {
                                    width: 70px;
                                }
                            }

                            &.name {
                                display: inline-block;

                                .text {
                                    font-size: 18px;
                                }

                                i {
                                    margin-left: 5px;

                                    @media (max-width: 991px) {
                                        margin-left: 10px;
                                    }
                                }
                            }
                        }
                    }

                    .sidebarMenu {
                        .menuLabel {
                            display: block;
                        }

                        .submenu {
                            display: block;
                        }

                        .navItems {
                            .navItemsTextContent {
                                .icon {
                                    height: 21px;
                                }

                                .name {
                                    display: block;
                                }

                                .value {
                                    display: flex;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.settingBtns {
    position: fixed;
    z-index: 111;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    li {
        margin-bottom: 2px;

        .settingsWrap {
            width: 45px;
            height: 45px;
            min-width: 45px;
            background: linear-gradient(45deg, #6e5ad8, #139aea);
            box-shadow: 0 1px 30px 5px rgba(0, 0, 0, 0.11);
            color: #ffffff;
            font-size: 18px;
            border-radius: 0px;
            transition: all 0.4s ease-in-out 0s;
            padding: 5px;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }

        .rtlDemoBtn {
            font-size: 14px;
        }
    }
}
.settingBtns-2{
    top: 57%;
    .settingsWrap{
        display: block;
        text-align: center;
        line-height: 33px;
        margin-top: 20px;
    }
}


.breadcumbTitle {
    font-size: 28px;
    line-height: 33px;
    margin-bottom: 35px;

    @media (max-width: 1200px) {
        font-size: 25px;
    }

    @media (max-width: 500px) {
        font-size: 24px;
    }
}

.backDrop {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    top: 0;
}

.p-50 {
    padding: 50px;

    @media (max-width: 991px) {
        padding: 50px 16px;
    }
}

.settingSidebarDemo {
    h3 {
        color: #fff;
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 30px;
        text-transform: capitalize;
    }
}

.demoLinks {
    li {
        margin-bottom: 15px;

        a {
            display: block;
            position: relative;
            z-index: 1;

            img {
                width: 100%;
            }

            h4 {
                position: absolute;
                left: 0;
                transition: all 0.4s ease-in-out 0s;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 18px;
                font-weight: 500;
                z-index: 11;
                justify-content: center;
                background: linear-gradient(rgba(151, 149, 231, 0.85) 0%, rgba(84, 78, 250, 0.85) 100%);
                opacity: 0;
                visibility: hidden;
                color: #fff;
            }

            &:hover {
                h4 {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}
