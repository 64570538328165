@import '../docs/css/mixin.scss';

.accountArea {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 100px 0;
  background: #3B7CDD;

  @media (max-width: 991px) {
    padding: 50px 16px;
  }
}

.accountWrapper {
  width: 1170px;
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  margin: auto;
  box-shadow: 0px 14px 60px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  overflow: hidden;

  position: relative;
  z-index: 1;

  @media (max-width: 1279px) {
    width: 900px;
  }

  @media (max-width: 991px) {
    width: 100%;
  }

  .bgShaperight {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    width: 55%;
    height: 100%;

    @media (max-width: 991px) {
      display: none;
    }
  }

}

.fromTitle {

  h2 {
    font-size: 30px;
    margin-bottom: 30px;
  }

  p {
    font-size: 15px;
    margin-bottom: 40px;
  }
}

.accountForm {
  width: 50%;
  padding: 70px 85px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 991px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    padding: 50px 30px;
  }

  .formInput {
    margin-bottom: 30px;

    .MuiInputBase-formControl {
      fieldset {
        border: 1px solid #e5e5e5 !important;
      }

      &.Mui-focused {
        fieldset {
          border: 1px solid $baseColor !important;
        }
      }
    }

    .showPassword {
      cursor: pointer;
    }
  }

  .loginAction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;

    .remambar {
      margin-right: 0;
    }

    a {
      display: inline-block;
      font-size: 14px;
      color: $baseColor;

      &:hover {
        opacity: .8;
      }
    }
  }

  .accountBtn {
    font-size: 16px;
    padding: 10px 20px;
    border: 2px solid $baseColor;

    &:hover {
      color: $baseColor;
      background: transparent !important;
    }
  }
}

.or {
  border-top: 1px solid #3c54db;
  margin-top: 45px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;

  span {
    font-size: 16px;
    text-transform: uppercase;
    display: inline-block;
    padding: 0 25px;
    background: #fff;
    position: relative;
    top: -14px;
    color: $baseColor;
  }
}

.socialLoginBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;

  li {
    flex: 1;
    margin: 0px 5px 5px;

    button {
      width: 100%;
      height: 30px;
      min-width: 92px;
      font-size: 15px;
      border-radius: 3px;
      overflow: hidden;
      cursor: pointer;
      position: relative;
      border: none;
      text-transform: capitalize;
      font-weight: 400;
      color: #fff;

      &:focus {
        outline: none;
      }

      &.facebook {
        background: #3b5998;
      }

      &.twitter {
        background: #55acee;
      }

      &.linkedin {
        background: #0077B5;
      }
    }
  }
}

.subText {
  text-align: center;

  a {
    display: inline-block;
    font-size: 14px;
    color: $baseColor;

    &:hover {
      opacity: .8;
    }
  }
}
