.bg-primary {
    background: #1DAEFF !important;
}

.bg-warning {
    background: #DFA100 !important;
}

.bg-success {
    background: #02AE14 !important;
}

.bg-danger {
    background: #FF3737 !important;
}

.bg-secondary {
    background: #6c757d !important;
}

.bg-info {
    background: #1AB8D2 !important;
}

.bg-light {
    background: #f8f9fa !important;
    color: #31373C !important;
}

.bg-dark {
    background: #31373C !important;
}

.bg-default {
    background: #3c54db !important;
}

.text-success {
    color: #02AE14 !important;
}

.text-primary {
    color: #1DAEFF !important;
}

.text-secondary {
    color: #6c757d !important;
}

.text-danger {
    color: #FF3737 !important;
}

.text-warning {
    color: #DFA100 !important;
}

.text-info {
    color: #1AB8D2 !important;
}

.text-light {
    color: #f8f9fa !important;
}

.text-dark {
    color: #31373C !important;
}

.text-default {
    color: #604BFE !important;
}


.border-success {
    border-color: #02AE14 !important;
}

.border-primary {
    border-color: #1DAEFF !important;
}

.border-secondary {
    border-color: #6c757d !important;
}

.border-danger {
    border-color: #FF3737 !important;
}

.border-warning {
    border-color: #DFA100 !important;
}

.border-info {
    border-color: #1AB8D2 !important;
}

.border-light {
    border-color: #f8f9fa !important;
}

.border-dark {
    border-color: #31373C !important;
}

.border-default {
    border-color: #604BFE !important;
}


.imgAvatar {
    width: 35px;
    border-radius: 50%;
}



.badge-default {
    background: #3c54db!important;
}

.badge-warning {
    background: #FFEDBC !important;
}

.badge-success {
    background: #78d885!important;
}

.badge-danger {
    background: #f46e6e!important;
}

.badge {
    height: 25px;
    font-size: 12px;
    padding: 0px 20px;
    display: inline-flex;
    align-items: center;
    border-radius: 3px;
    justify-content: center;
    color: #fff;
}

.avatarImg {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}
