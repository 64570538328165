@import "../../docs/css/mixin.scss";

.cardWrapper {
  background: #fff;
  padding: 25px 30px;
  min-height: 100%;
  background: #ffffff;
  box-shadow: 0px 14px 60px rgba(0, 0, 0, 0.06);
  border-radius: 9px;
  transition: all 0.4s ease-in-out 0s;
  @media (max-width: 767px) {
    padding: 25px 15px;
  }

  &.p-0 {
    padding: 0 !important;

    .cardHeader {
      padding-top: 30px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    flex-wrap: wrap;

    @media (max-width: 1700px) {
      margin-bottom: 25px;
    }

    h3 {
      font-size: 16px;
      line-height: 28px;
      text-transform: capitalize;
      font-weight: 800;
    }

    .subtitle {
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.118182px;
      color: #646079;
      display: block;
    }

    .cartTabs {
      border-bottom: 1px solid #f0f0f9;

      @media (max-width: 767px) {
        margin-top: 20px;
      }

      button {
        min-width: unset;
        min-height: 40px;
        height: 40px;
        padding: 0 25px;
        font-size: 16px;
        color: $textColor;
        text-transform: capitalize;
        opacity: 1;

        &[aria-selected="true"] {
          color: $baseColor;
        }
      }

      .indigator {
        height: 3px;
        background: $baseColor;
      }
    }
  }

  &.paddingOut {
    padding: 30px 0px;

    h3 {
      padding: 0px 30px;
    }
  }

  .dropDownSelect {
    @media (max-width: 767px) {
      margin-top: 20px;
    }

    .MuiInputBase-root {
      background: transparent;
      background: #ffffff;
      border: 1px solid #eff2f4;
      border-radius: 3px;

      &.Mui-focused {
        border-color: $baseColor;
        background: #fff;
      }

      &::before,
      &::after {
        display: none;
      }

      .MuiSelect-root {
        padding: 0;
        width: 120px;
        height: 30px;
        display: flex;
        align-items: center;
        padding: 0px 10px;

        &:focus {
          background: #fff;
        }
      }
    }
  }
}

.squareBoxCard {
  .cardWrapper {
    border-radius: 0px;
  }
}

.withoutShadowCard {
  .cardWrapper {
    box-shadow: none;
  }
}

.seeAll {
  a {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1px;
    color: #878aa0;

    &:hover {
      color: #3b53db;
    }
  }
}

.hasBorderBottom {
  position: relative;
  z-index: 1;
}
