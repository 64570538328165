@import '../../docs/css/mixin.scss';

.sidebarMainWrapper {
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  position: relative;
  background: #FFFFFF;

  .colupsMenuSidebar {
    display: none;

    @media (max-width: 991px) {
      display: flex;
      width: 45px;
      height: 45px;
      position: absolute;
      right: 0px;
      top: 0;
      z-index: 111;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      path{
        fill: #ffffff;
      }
    }
  }

  @media (max-width: 1440px) {
    width: 230px;
    min-width: 230px;
    max-width: 230px;
  }

  @media (max-width: 991px) {
    position: fixed;
    z-index: 111;
    left: 0;
    background: #EAE8F7;
    height: 100%;
    width: 230px;
    min-width: 230px;
    max-width: 230px;
    left: -230px;
    transition: all .4s ease-in-out 0s;
  }

  .sidebarWrap {
    position: fixed;
    width: 320px;
    min-width: 320px;
    max-width: 320px;

    @media (max-width: 1440px) {
      width: 230px;
      min-width: 230px;
      max-width: 230px;
    }

  }

}

.logo {
  a {
    height: 80px;
    align-items: center;
    display: flex;
    padding: 0 30px;
    background: #2563a4;

    @media (max-width: 1279px) {
      padding: 30px 20px;
    }

    img {
      position: absolute;
      max-width: 97px;

      &.colupsLogo {
        opacity: 0;
      }
    }
  }
}

.sidebarMenu {
  height: calc(100vh - 80px);
  background: #ffffff;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    background: linear-gradient(45deg, #3e2c9c, #217db3);
    opacity: 0.9;
  }

  .scrollbar-container {
    padding-bottom: 20px;
    // padding-right: 12px;
  }

  .menuLabel {
    display: block;
    font-size: 1rem;
    color: $headingColor;
    margin-bottom: 20px;
    font-weight: 500;
    padding: 0px 40px;

    @media (max-width: 1279px) {
      font-size: 16px;
      padding: 0px 20px;
    }
  }

  .navItems {
    min-height: 45px;
    width: 100%;
    box-shadow: none;
    background: transparent;

    &::before {
      display: none;
    }

    .name{
      svg{
        width: 18px;
        // display: block;
        // position: relative;
        margin-right: 5px;
        font-size: 1rem;
          &.hover {
            filter: brightness(3);
          }
      }
    }

    .navItemsText {
      padding: 0;
      min-height: 50px;

      .navItemsTextContent {
        font-style: normal;
        font-weight: bold;
        padding-left: 30px;
        margin: 20px 0;

        span {
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
        }

        .icon {
          width: 18px;
          display: block;
          position: relative;
          margin-right: 10px;

          img {
            position: absolute;
            max-width: 100%;

            &.hover {
              filter: brightness(3);
            }
          }
        }
      }

      .navItemsTextIcon {
        span {
          font-size: 20px;
          color: #fff;
        }
      }
    }

    .navItemsTextExpanded {
      margin: 0;
    }

    &.navItemsExpanded {
      margin: 0 !important;
    }

    .submenu {
      li {
        a.navItem {
          display: block;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #bfc6ec;
          padding: 15px 0;
          padding-left: 58px;
          border-right: 5px solid transparent;

          position: relative;
          z-index: 1;

          &:hover {
            color: #ffffff;
          }

          &.active {
            border-color: #4578e0;
            color: #ffffff;
            background: rgba(52, 58, 153, 0.36);
          }

          .value {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 12px;
            font-weight: 500;
            color: #fff;
            height: 22px;
            min-width: 29px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
          }
        }

        .navItems {
          padding-left: 28px;
          padding-right: 0;
          min-height: unset;
          margin-bottom: 15px;

          .navItemsText {
            min-height: unset;
          }

          .navItemsTextContent {
            margin: 0;
          }

          .navItemsTextIcon {
            margin: 0;
            padding: 0;
            color: #fff;
          }
        }
      }

      .thirdmenuItems {
        li {
          a {
            display: block;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #bfc6ec;
            padding: 8px 0;
            padding-left: 40px;
            border-right: 5px solid transparent;

            &:hover {
              color: #ffffff;
            }

            &.active {
              border-color: #4578e0;
              color: #ffffff;
              background: rgba(52, 58, 153, 0.36);
            }
          }
        }
      }
    }
  }
}

.ps__thumb-y,
.ps__thumb-x {
  background-color: #2d88e5 !important;
}

.ps--clicking {
  background-color: rgba(62, 38, 154, 0.45) !important;
}
