.resumDashboardCard{
    max-height: 200px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 5px;
      }
    
      /* Track */
      &::-webkit-scrollbar-track {
        background: white;
      }
    
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgb(241, 241, 241);
      }
    
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: rgb(228, 228, 228);
      }
}