@import '../../docs/css/mixin.scss';

.headerArea {
  overflow: hidden;
  display: flex;
  align-items: center;
  min-height: 80px;
  border-bottom: 1px solid #E5E5E7;
  margin-bottom: 43px;
  background: #ffffff;
}

.headerRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  li {
    margin-right: 30px;
    min-height: 80px;
    display: inline-flex;
    align-items: center;

    .wrapper {
      cursor: pointer;
      position: relative;
      display: block;
    }

    &:last-child {
      margin-right: 0;
    }

    .value {
      position: absolute;
      right: -5px;
      top: 0;
      background: $baseColor;
      height: 15px;
      width: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 9px;
      color: #fff;
      border-radius: 50%;
      font-weight: 500;
    }

    .push {
      position: absolute;
      right: 0;
      top: 0;
      background: linear-gradient(180deg, #FF6167 0%, #FF0E27 100%);
      width: 10px;
      height: 10px;
      box-shadow: 0 0 0 rgba(255, 255, 255, 0.4);
      animation: pulse 1s infinite;
      border-radius: 50%;
      border: 2px solid #fff;
    }

    img {
      width: 22px;
      height: 17px;
      object-fit: contain;
      cursor: pointer;
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  }

  70% {
    -webkit-box-shadow: 0 0 0 5px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  }

  70% {
    -moz-box-shadow: 0 0 0 5px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 255, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 255, 0);
  }
}

.notificationWrapper {
  .notificationPaper {
    width: 300px;

    .notificationList {
      padding: 0px;

      h5 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        height: 60px;
        background: #3c54db;
        color: #fff;
        padding: 0 20px;
        font-size: 16px;
        text-transform: capitalize;
        font-weight: 600;
        margin-bottom: 0;

        span {
          font-weight: 400;
          color: #fff;
          cursor: pointer;
          font-size: 13px;
        }
      }

      .seeAll {
        display: flex;
        height: 50px;
        align-items: center;
        justify-content: center;
        padding: 20px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        border-top: 1px solid #f1f1f1;
        color: $baseColor;
        border-radius: 0px;

        &:hover {
          background: #fafafa;
        }
      }
    }
  }
}

.scrollbarArea {
  height: 260px;
}

.notificationItems {
  li {
    a {
      padding: 15px 20px;
      display: block;
      padding-left: 65px;
      position: relative;
      color: $textColor;
      border-radius: 0px;

      &:hover {
        background: #fbfbfb;
      }

      .icon {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        height: 30px;
        width: 30px;
        background: $baseColor;
        color: #fff;
        font-size: 12px;
        text-align: center;
        border-radius: 50%;
        line-height: 29px;
      }

      span {
        display: block;
        font-size: 14px;
        text-transform: capitalize;
        font-weight: 500;

        span {
          line-height: 15px;
          margin-top: 3px;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
}

.profileWrap {
  display: flex;
  align-items: center;

  span {
    &.profileImg {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0 0 11px rgba(0,0,0,0.15);

      img {
        max-width: 100%;
      }
    }

    &.name {
      display: inline-block;
      cursor: pointer;
      color: $headingColor;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;

      span.text {
        @media (max-width: 400px) {
          display: none;
        }
      }

      i {
        margin-left: 5px;
      }
    }
  }
}

.profileWrapper {
  .profilePaper {
    width: 200px;

    .profileList {
      padding: 0px 0;

      li {
        a {
          border-radius: 0px;
          display: block;
          text-transform: capitalize;
          padding: 10px 15px;
          font-size: 15px;
          width: 100%;
          text-align: left;

          i {
            margin-right: 10px;
          }

          &:hover {
            background: #fafafa;
            color: $baseColor;
          }
        }
      }
    }
  }
}
